import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import AnchorLink from '../../UI/atoms/AnchorLink';

const DesktopMenu = ({ menuItems, ...props }) => {
  const { pathname } = useLocation();

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      pointerEvents="none"
      {...props}
    >
      {menuItems.map(({ Icon, text, path, routes }) => {
        const isActive = routes.includes(pathname);
        return (
          <AnchorLink
            key={text}
            to={path}
            transform="translateX(calc(-100% + 55px))"
            boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.2)"
            borderTopRightRadius="22px"
            borderBottomRightRadius="22px"
            border="solid 1px #ececec"
            background="white"
            color="text700"
            fontSize="14px"
            fontWeight="600"
            height="44px"
            display="flex"
            alignItems="center"
            padding="0 15px"
            marginBottom="10px"
            transition="all 0.3s ease"
            _hover={{
              transform: 'translateX(0)',
            }}
            pointerEvents="auto"
          >
            <Text
              display="block"
              transition="all 0.3s ease"
              marginRight="15px"
              whiteSpace="nowrap"
              color={isActive ? 'text700' : 'text400'}
            >
              {text}
            </Text>
            <Text display="flex" flex="0 0 30px" alignItems="center">
              <Icon greyScale={!isActive} />
            </Text>
          </AnchorLink>
        );
      })}
    </Container>
  );
};

DesktopMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
};

export default DesktopMenu;
