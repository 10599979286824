import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';
import SearchInput from '../../../UI/molecules/SearchInput';
import { googlePackageLocationSuggest as googlePackageLocationSuggestQuery } from '../../../../graphql/queries';

const LocationSuggest = ({ onSelect }) => {
  const searchInputRef = useRef();
  const autoSuggestRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState();

  const [
    getSuggestedLocations,
    { data: suggestedLocationData, loading: fetchLoading },
  ] = useLazyQuery(googlePackageLocationSuggestQuery);

  useEffect(() => {
    if (suggestedLocationData) {
      let { googlePackageLocationSuggest: items } = suggestedLocationData;
      items = items.map(item => ({
        ...item,
        displayName: `${item.name} (${item.targetType})`,
      }));
      setResults(searchValue ? items : null);
    }
  }, [suggestedLocationData]);

  const onSearchChange = ({ target: { value } }) => {
    setSearchValue(value);

    if (value) {
      getSuggestedLocations({ variables: { query: value } });
    } else {
      setResults(null);
    }
  };

  const onKeyDown = e => {
    const { which } = e;

    if (which === 40) {
      e.preventDefault();
      autoSuggestRef?.current?.firstChild.focus();
    }
  };

  const onSearchInputClear = () => {
    setSearchValue('');
    searchInputRef.current.focus();
    onSelect(null);
  };

  const onAutoSuggestSelect = obj => {
    setResults(null);
    setSearchValue(obj.name);
    onSelect(obj);
  };

  return (
    <Container display="flex">
      <Container flex="1">
        <SearchInput
          ref={searchInputRef}
          value={searchValue}
          icon="icon-generalpindropmini"
          onChange={onSearchChange}
          onKeyDown={onKeyDown}
          onClear={onSearchInputClear}
          placeholder="Search Locations"
          flex="1"
        />
        <Container position="relative" marginTop="5px" marginBottom="20px">
          <AutoSuggest
            ref={autoSuggestRef}
            data={results}
            loading={fetchLoading}
            onSelect={onAutoSuggestSelect}
            itemKey="id"
            itemDisplayText="displayName"
            maxHeight="255px"
          />
        </Container>
      </Container>
    </Container>
  );
};

LocationSuggest.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default LocationSuggest;
