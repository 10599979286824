import { useLayoutEffect, useState } from 'react';

const useWindowSize = () => {
  const [size, setSize] = useState({
    winWidth: undefined,
    winHeight: undefined,
  });

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({ winWidth: window.innerWidth, winHeight: window.innerHeight });
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};

export default useWindowSize;
