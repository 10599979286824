import React from 'react';
import PropTypes from 'prop-types';

const MenuIconMap = ({ greyScale }) => (
  <svg
    width="26"
    height="22"
    viewBox="0 0 26 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={greyScale ? '#C4C4C4' : '#44D7B6'}>
        <path d="m1.23 17.136 5.738 2.492c.329.143.709.115 1.012-.073.302-.189.485-.513.485-.86V3.516c0-.403-.245-.767-.627-.932L2.1.091a1.09 1.09 0 0 0-1.012.073 1.013 1.013 0 0 0-.484.86V16.2a1.02 1.02 0 0 0 .626.936zM10.87 19.625l5.738-2.492c.38-.164.626-.53.626-.933V1.027c0-.348-.182-.672-.484-.86a1.094 1.094 0 0 0-1.012-.073L10 2.584a1.019 1.019 0 0 0-.627.931v15.177c-.001.348.183.672.485.86.302.189.683.216 1.012.073zM18.765 17.136l5.739 2.492c.328.143.708.115 1.012-.073.302-.189.484-.513.484-.86V3.516c0-.403-.245-.767-.626-.932L19.639.091a1.09 1.09 0 0 0-1.012.073 1.012 1.012 0 0 0-.485.86V16.2c-.002.403.243.77.624.936z" />
      </g>
      <g transform="translate(0 6.315)">
        <ellipse fill="#FFF" cx="8.311" cy="8.963" rx="5.079" ry="4.93" />
        <path
          d="M15.947 4.538C15.695 3.97 15 3.54 14.4 3.582c-.6.041-1.292-.187-1.599-.47-.307-.284-.556-.956-.533-1.54.022-.586-.441-1.242-1.035-1.47-.593-.23-1.394-.056-1.788.382-.394.438-1.055.76-1.475.772-.42.012-1.09-.294-1.497-.72-.407-.427-1.216-.58-1.8-.334-.582.244-1.029.919-.986 1.501.042.582-.193 1.255-.485 1.553-.292.298-.984.537-1.584.518-.6-.02-1.278.43-1.514 1.005S.047 6.133.498 6.516c.451.382.783 1.024.795 1.43.013.406-.3 1.057-.741 1.453-.441.395-.6 1.18-.344 1.749.255.569.946.999 1.546.958.6-.041 1.293.187 1.6.47.306.284.555.953.533 1.538-.023.585.44 1.24 1.035 1.47.595.23 1.395.055 1.789-.383.395-.437 1.053-.76 1.474-.765.42-.006 1.089.296 1.5.723.41.426 1.218.572 1.8.328.584-.245 1.03-.916.988-1.499-.043-.582.192-1.255.484-1.552.293-.298.983-.537 1.585-.513.603.023 1.278-.429 1.514-1.006s.06-1.353-.394-1.74c-.454-.386-.785-1.02-.797-1.432-.01-.412.303-1.057.745-1.452.45-.474.58-1.157.337-1.756zm-6.353 6.409v-.001a3.319 3.319 0 0 1-2.475.036A3.189 3.189 0 0 1 5.344 9.31a3.05 3.05 0 0 1-.036-2.401A3.18 3.18 0 0 1 7.03 5.186a3.324 3.324 0 0 1 2.474-.035c.797.308 1.435.91 1.774 1.675.34.762.353 1.624.038 2.397a3.18 3.18 0 0 1-1.719 1.723h-.004z"
          fill={greyScale ? '#9B9B9B' : '#285AFF'}
        />
      </g>
    </g>
  </svg>
);

MenuIconMap.propTypes = {
  greyScale: PropTypes.bool,
};

MenuIconMap.defaultProps = {
  greyScale: false,
};

export default MenuIconMap;
