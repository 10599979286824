import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import SelectButton from 'ls-common-client/src/components/SelectButton';
import Loader from 'ls-common-client/src/components/Loader';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';

const RelatedCategories = ({
  relatedCategories,
  onRelatedCategorySelect,
  relatedCategoriesLoading,
  // eslint-disable-next-line react/prop-types
  onAddRelatedCategoriesClick,
  ...props
}) => (
  <Container position="relative" {...props}>
    <Container
      backgroundColor="background300"
      borderRadius="20px"
      height="100%"
      width="100%"
      position="absolute"
    >
      <Container
        position="absolute"
        left="0"
        top="0"
        width="100%"
        height="100%"
        boxShadow="inset -1px 0 10px 0 rgba(0, 0, 0, 0.1)"
        borderRadius="20px"
        zIndex="2"
        pointerEvents="none"
      />
      {relatedCategoriesLoading ? (
        <Container
          position="absolute"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="2"
        >
          <Loader width="200px" />
        </Container>
      ) : (
        <Container
          className="relatedCategoriesScrollContainer"
          overflow="auto"
          display="flex"
          flexWrap="wrap"
          padding="5px"
          maxHeight="100%"
        >
          {relatedCategories.map(({ name, selected, id }, i) => (
            <SelectButton
              key={id}
              onClick={() => onRelatedCategorySelect(i)}
              selected={selected}
              margin="3px"
              paddingLeft="6px"
            >
              <Text
                width="18px"
                height="18px"
                borderRadius="100%"
                backgroundColor={selected ? 'white' : 'background300'}
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginRight="12px"
              >
                <Icon className="ls-icon icon-plus" />
              </Text>
              {name}
            </SelectButton>
          ))}
        </Container>
      )}
    </Container>
  </Container>
);

RelatedCategories.propTypes = {
  relatedCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      selected: PropTypes.bool,
      id: PropTypes.string,
    })
  ).isRequired,
  onRelatedCategorySelect: PropTypes.func.isRequired,
  relatedCategoriesLoading: PropTypes.bool.isRequired,
};

export default RelatedCategories;
