import React, { createContext, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import NotificationSlider from 'ls-common-client/src/components/NotificationSlider';
import useMedia from 'ls-common-client/src/hooks/useMedia';
import useUser from './hooks/useUser';
import useNotificationSlider from './hooks/useNotificationSlider';

const Context = createContext();

const Provider = ({ children }) => {
  const portalRef = useRef();
  const { mediaQueries } = useContext(ThemeContext);
  const media = useMedia(mediaQueries);
  const user = useUser();
  const notificationSlider = useNotificationSlider();

  const value = useMemo(
    () => ({
      media,
      user,
      notificationSlider,
    }),
    [media, user, notificationSlider]
  );

  return (
    <Context.Provider value={value}>
      {children}
      {notificationSlider.notifications.map(props => (
        <NotificationSlider
          mode={media.mobile ? 'mobile' : 'desktop'}
          zIndex="10"
          portalElement={portalRef.current}
          position="relative"
          width={media.mobile ? '100%' : '465px'}
          {...props}
        />
      ))}
      <Container
        ref={portalRef}
        position="fixed"
        zIndex="101"
        bottom="0"
        left="0"
        right="0"
      />
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export { Provider, Context };
