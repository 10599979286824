import React from 'react';
import PropTypes from 'prop-types';

const MenuIconCategories = ({ greyScale }) => (
  <svg
    width="26"
    height="21"
    viewBox="0 0 26 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={greyScale ? '#c4c4c4' : '#44D7B6'}>
        <rect width="22.746" height="4.846" rx="2.423" />
        <rect y="6.462" width="22.746" height="4.846" rx="2.423" />
        <rect y="12.923" width="22.746" height="4.846" rx="2.423" />
      </g>
      <g transform="translate(9.748 4.846)">
        <ellipse fill="#FFF" cx="6.647" cy="6.608" rx="5.17" ry="5.14" />
        <path
          d="M6.902 0a6.92 6.92 0 0 0-5.585 2.832 6.834 6.834 0 0 0-.979 6.156 6.885 6.885 0 0 0 4.433 4.407 6.94 6.94 0 0 0 6.192-.976l3.439 3.419c.423.421 1.11.421 1.532 0a1.072 1.072 0 0 0 0-1.523l-3.438-3.419v-.001a6.828 6.828 0 0 0-.969-9.139A6.919 6.919 0 0 0 6.902 0zm0 11.576a4.754 4.754 0 0 1-3.351-1.38 4.699 4.699 0 0 1-1.388-3.33c0-1.25.5-2.45 1.388-3.332a4.754 4.754 0 0 1 3.35-1.38c1.257 0 2.463.496 3.352 1.38a4.699 4.699 0 0 1 1.387 3.331 4.7 4.7 0 0 1-1.39 3.33 4.755 4.755 0 0 1-3.348 1.381z"
          fill={greyScale ? '#9b9b9b' : '#285AFF'}
        />
      </g>
    </g>
  </svg>
);

MenuIconCategories.propTypes = {
  greyScale: PropTypes.bool,
};

MenuIconCategories.defaultProps = {
  greyScale: false,
};

export default MenuIconCategories;
