import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import ToolTip from 'ls-common-client/src/components/ToolTip';
import { Context } from '../../../context/AppContext';

const TitleToolTip = ({
  popupProps,
  anchor,
  heading,
  inactive,
  tooltip,
  ...props
}) => {
  const [show, setShow] = useState();
  const {
    media: { mobile },
  } = useContext(Context);
  return (
    <ToolTip
      onMouseEnter={() => !inactive && setShow(true)}
      onMouseLeave={() => !inactive && setShow(false)}
      show={show}
      arrowAnchor="top"
      anchor="topRight"
      render={() => (
        <Container display="flex" alignItems="center" marginBottom="5px">
          <Text
            color="#757575"
            fontWeight="normal"
            lineHeight="20px"
            fontSize="14px"
          >
            {tooltip}
          </Text>
        </Container>
      )}
      popupProps={{
        width: '234px',
        height: 'auto',
        left: mobile ? '-18px' : '-42px',
        minWidth: 'unset',
        padding: '12px',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...popupProps,
      }}
      {...props}
    >
      <Icon
        fontSize="20px"
        className="icon-info1"
        color="#757575"
        cursor="pointer"
        _hover={{
          color: 'primary',
        }}
      />
    </ToolTip>
  );
};

TitleToolTip.propTypes = {
  popupProps: PropTypes.shape({}),
  anchor: PropTypes.string,
  heading: PropTypes.string,
  inactive: PropTypes.bool,
  tooltip: PropTypes.string,
};

TitleToolTip.defaultProps = {
  popupProps: {},
  anchor: 'topRight',
  heading: '',
  inactive: false,
  tooltip: '',
};

export default TitleToolTip;
