import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
// import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import Validator from 'ls-common-client/src/components/Validator';
import Button from 'ls-common-client/src/components/Button';
import Input from '../../../UI/atoms/Input';
import linkIcon from '../../../../images/link.svg';

const isValidUrl = url =>
  /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6}\.?)(\/[\w.-]*)*\/?$/.test(url);

const UrlForm = ({ onClickLoad, loading, ...rest }) => {
  const [url, setUrl] = useState('');
  const [urlValid, setUrlValid] = useState(true);

  const onChange = useCallback(e => {
    const value = e.target.value.trim();
    setUrl(value);
    setUrlValid(isValidUrl(value));
  }, []);

  const onSearch = useCallback(async () => {
    if (loading) {
      return;
    }
    const valid = isValidUrl(url);
    setUrlValid(valid);
    if (!valid) {
      return;
    }
    onClickLoad(url);
  }, [loading, url]);

  const onKeyPress = useCallback(
    event => {
      if (!urlValid || !url || event?.key !== 'Enter') {
        return;
      }
      onClickLoad(url);
    },
    [url, urlValid]
  );

  return (
    <Container {...rest}>
      <Container
        display="flex"
        marginBottom="25px"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Text
          fontWeight="600"
          fontSize="25px"
          lineHeight="1.2"
          marginRight="20px"
          marginBottom="20px"
        >
          SEO Opportunity Tool
        </Text>
      </Container>
      <Container>
        <Text
          fontWeight="450"
          display="block"
          marginBottom="15px"
          lineHeight="1.2"
        >
          URL
        </Text>
        <Container position="relative">
          <Image src={linkIcon} position="absolute" left="20px" top="12px" />
          {/*
          <Icon
            className="ls-icon icon-search"
            icon
            color="text300"
            position="absolute"
            left="20px"
            top="12px"
            fontSize="19px"
          />
          */}
          <Input
            placeholder="Enter URL"
            onChange={onChange}
            value={url}
            disabled={loading}
            onKeyPress={onKeyPress}
          />
        </Container>
        <Validator
          marginTop="8px"
          style={{
            display: urlValid ? 'none' : 'block',
          }}
        >
          Please input a valid URL.
        </Validator>
        <Container display="flex" marginTop="20px">
          <Container>
            <Button
              rounded
              primary
              onClick={onSearch}
              disabled={!urlValid || loading}
              loading={loading}
            >
              Search
            </Button>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

UrlForm.propTypes = {
  onClickLoad: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UrlForm;
