import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Loader from 'ls-common-client/src/components/Loader';
import Button from 'ls-common-client/src/components/Button';
import Anchor from 'ls-common-client/src/components/Anchor';
import Icon from 'ls-common-client/src/components/Icon';
import styled from 'styled-components';
import Question from './Question';
import Panel from '../../../UI/atoms/Panel';
import seoPackage from '../../../../images/seoPackage.svg';
import { Context } from '../../../../context/AppContext';
import { seoPackageCalculate as seoPackageCalculateQuery } from '../../../../graphql/queries';
import { contactUsMailToPath } from '../../../../../config';

const LineBreak = styled.br`
  @media (min-width: 1450px) {
    display: none;
  }
`;

const booleanItems = [
  {
    text: 'No',
    value: false,
  },
  {
    text: 'Yes',
    value: true,
  },
];

const regionItems = [
  {
    text: 'Regional',
    value: 'regional',
  },
  {
    text: 'Metro',
    value: 'metro',
  },
  {
    text: 'Mix of Metro & Regional',
    value: 'mixMetroRegional',
  },
  {
    text: 'National',
    value: 'national',
  },
];

const locationQuantityItems = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3 or more', value: 'more' },
];

const competitionItems = [
  {
    text: 'Low',
    value: 'low',
  },
  {
    text: 'Medium',
    value: 'medium',
  },
  {
    text: 'High',
    value: 'high',
  },
];

const description = {
  REGION:
    'Metro locations include big areas within Australia such as Brisbane, Sydney, Melbourne, Gold Coast, Perth, Adelaide and Canberra.',
  LOCATIONS:
    'A multiple location package will include the locations on the website and the GMB profiles.',
  MULTIPLE_PRODUCTS:
    'What is considered multiple services: if the website has/should have separate service pages for their target keywords. For example plumber and blocked drains are multiple services. ',
  BUSINESS_YMYL:
    'Your Money or Your Life websites are those that may impact a persons future happiness, health, financial stability or safety. For example Healthcare, Doctors, Dentists, Legal or Financial websites.',
  COMPETITION:
    'Highly competitive categories are those that have high competition in their area. Examples of highly competitive industries include trades such plumbers, doctors, roofers, and dentists. Lower competition would be niche topics that don’t have a huge range of businesses competing for the top spot.',
};

const PackageCalculator = props => {
  const {
    media: { mobile },
  } = useContext(Context);

  const [region, setRegion] = useState();
  const [locationQuantity, setLocationQuantity] = useState();
  const [ymyl, setYMYL] = useState();
  const [multi, setMulti] = useState();
  const [competition, setCompetition] = useState();
  const [valid, setValid] = useState();
  const [results, setResults] = useState();

  const [calculate, { data, loading }] = useLazyQuery(seoPackageCalculateQuery);

  useEffect(() => {
    if (data) {
      const { seoPackageCalculate: result } = data;
      setResults(result);
    }
  }, [data]);

  useEffect(() => {
    const isValid =
      region &&
      locationQuantity &&
      ymyl !== undefined &&
      multi !== undefined &&
      competition;
    setValid(isValid);

    if (!isValid) {
      return;
    }

    calculate({
      variables: {
        region,
        locationQuantity,
        ymyl,
        multi,
        competition,
      },
    });
  }, [region, locationQuantity, ymyl, multi, competition]);

  const onReset = () => {
    setRegion(undefined);
    setLocationQuantity(undefined);
    setYMYL(undefined);
    setMulti(undefined);
    setCompetition(undefined);
  };

  return (
    <Container display="flex" flexWrap="wrap" width="100%" {...props}>
      <Panel
        margin="10px"
        flex="1"
        minWidth={mobile ? '300px' : '400px'}
        display="flex"
        flexDirection="column"
      >
        <Container
          display="flex"
          marginBottom="25px"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Text
            fontWeight="600"
            fontSize="25px"
            lineHeight="1.2"
            marginRight="20px"
            marginBottom="20px"
          >
            SEO Calculator
          </Text>
        </Container>
        <Question
          text="Select region"
          items={regionItems}
          selected={region}
          onSelect={setRegion}
          tooltip={description.REGION}
          required
        />
        <Question
          text="How many locations?"
          items={locationQuantityItems}
          selected={locationQuantity}
          onSelect={setLocationQuantity}
          tooltip={description.LOCATIONS}
          required
        />
        <Question
          text={
            <>
              Do you want to target multiple <LineBreak /> products/services?
            </>
          }
          items={booleanItems}
          selected={multi}
          onSelect={setMulti}
          tooltip={description.MULTIPLE_PRODUCTS}
          required
        />
        <Question
          text="Is your Business YMYL?"
          items={booleanItems}
          selected={ymyl}
          onSelect={setYMYL}
          tooltip={description.BUSINESS_YMYL}
          required
        />
        <Question
          text="How strong is your competition?"
          items={competitionItems}
          selected={competition}
          onSelect={setCompetition}
          tooltip={description.COMPETITION}
          required
        />
        <Container display="flex">
          <Container>
            <Button rounded primary margin onClick={onReset}>
              <Icon className="icon-rotate-ccw" marginRight="8px" />
              Reset
            </Button>
          </Container>
        </Container>
      </Panel>
      <Panel
        margin="10px"
        flex="2"
        display="flex"
        flexDirection="column"
        minWidth={mobile ? '300px' : '400px'}
        alignItems="center"
        background="linear-gradient(109.54deg, #6793EE 0%, #B164FF 92.01%)"
      >
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          flexDirection="column"
          color="#fff"
        >
          <Container marginBottom="40px" marginTop={mobile ? '10px' : '180px'}>
            <Container
              backgroundColor="#fff"
              height="142px"
              width="142px"
              borderRadius="500px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image src={seoPackage} width="50px" height="50px" />
            </Container>
          </Container>
          <Container flex="1" minHeight="180px">
            <Container
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {!valid && <Text>Start searching to see cost.</Text>}
              {valid && loading && (
                <Container>
                  <Text>Your SEO Package Cost is:</Text>
                  <Container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="112px"
                  >
                    <Loader width="200px" />
                  </Container>
                </Container>
              )}
              {valid && !loading && results?.package && (
                <Container
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  {results?.package !== 3000 ? (
                    <>
                      <Text>Your SEO Package Cost is:</Text>
                      <Container
                        display="flex"
                        alignItems="center"
                        marginTop="10px"
                      >
                        <Text fontSize="42px" fontWeight="600">
                          ${results.package}
                        </Text>
                        &nbsp;
                        <Text>/ per month</Text>
                      </Container>
                    </>
                  ) : (
                    <>
                      <Text>Your SEO package</Text>
                      <Container
                        display="flex"
                        alignItems="center"
                        marginTop="10px"
                      >
                        <Text
                          textAlign="center"
                          fontSize="32px"
                          fontWeight="600"
                        >
                          SEO Custom
                          <br />${results.package} Package
                        </Text>
                      </Container>
                    </>
                  )}
                </Container>
              )}
              {valid && !loading && !results?.package && (
                <Container
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Text>Your SEO Package</Text>
                  <Text fontSize="32px" marginTop="20px" fontWeight="600">
                    Custom Pricing
                  </Text>
                  <Text marginTop="20px" textAlign="center">
                    Contact the SEO team via productsquoting@localsearch.com.au
                    for further information.{' '}
                    <b>
                      Please provide relevant information in your email, such as
                      website URL, and which services and locations you need a
                      quote for.
                    </b>
                  </Text>
                  <Anchor
                    backgroundColor="#fff"
                    padding="10px 20px"
                    borderRadius="100px"
                    fontWeight="600"
                    marginTop="30px"
                    href={contactUsMailToPath}
                    target="_blank"
                  >
                    Contact Us
                  </Anchor>
                </Container>
              )}
            </Container>
          </Container>
        </Container>
      </Panel>
    </Container>
  );
};

export default PackageCalculator;
