import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Dialog from 'ls-common-client/src/components/Dialog';
import { Context } from '../../../context/AppContext';

const sortValues = {
  packageDistanceRankName: 'Package, Distance, Rank, Name',
  packageRankDistanceName: 'Package, Rank, Distance, Name',
};

const ServiceAreaDialog = ({
  show,
  onClose,
  searchAreaData,
  statisticalAreasData,
  onRemoveStatisticalArea,
}) => {
  const {
    media: { mobile },
  } = useContext(Context);

  const { name, description, sort } = searchAreaData || {};

  return (
    <Dialog show={show} onClose={onClose} mode={mobile ? 'mobile' : 'desktop'}>
      <Container padding="25px">
        <Container position="relative" marginBottom="10px">
          <Text fontSize="25px" fontWeight="600">
            Service Area Info
          </Text>
          <EmptyButton
            onClick={onClose}
            padding="13px"
            position="absolute"
            right="-12px"
            top="-10px"
          >
            <Icon className="ls-icon icon-generalxlarge" color="normal" />
          </EmptyButton>
        </Container>
        <Container>
          <Text display="flex" alignItems="center" marginBottom="15px">
            <Icon
              className="ls-icon icon-generalpindropmini"
              color="#f16159"
              fontSize="17px"
              marginRight="5px"
            />{' '}
            {name} | {description}
          </Text>
          <Container marginBottom="15px">
            <Text
              marginBottom="5px"
              display="block"
              fontWeight="600"
              color="text500"
            >
              Sort
            </Text>
            <Container
              borderRadius="7px"
              backgroundColor="background300"
              padding="15px"
            >
              {sortValues[sort]}
            </Container>
          </Container>

          <Container marginBottom="15px">
            <Text
              marginBottom="5px"
              display="block"
              fontWeight="600"
              color="text500"
            >
              Statistical Areas
            </Text>
            <Container display="flex" flexWrap="wrap" margin="0 -3px">
              {statisticalAreasData.map(area => (
                <Container
                  key={area.id}
                  borderRadius="30px"
                  border={theme => `1px solid ${theme.border.border300}`}
                  padding="5px 5px 5px 10px"
                  fontSize="14px"
                  fontWeight="600"
                  margin="3px"
                  display="flex"
                  alignItems="center"
                >
                  {area.name}
                  <EmptyButton
                    onClick={() => onRemoveStatisticalArea(area.id)}
                    width="22px"
                    height="22px"
                    backgroundColor="background300"
                    borderRadius="100%"
                    marginLeft="10px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon
                      className="ls-icon icon-x"
                      fontSize="14px"
                      color="text400"
                    />
                  </EmptyButton>
                </Container>
              ))}
            </Container>
          </Container>
        </Container>
      </Container>
    </Dialog>
  );
};

ServiceAreaDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  searchAreaData: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired,
  }),
  statisticalAreasData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  onRemoveStatisticalArea: PropTypes.func.isRequired,
};

ServiceAreaDialog.defaultProps = {
  show: false,
  searchAreaData: null,
  statisticalAreasData: null,
};

export default ServiceAreaDialog;
