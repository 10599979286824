import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';

const Panel = ({ children, ...props }) => (
  <Container
    background="white"
    borderRadius="20px"
    border="1px solid"
    borderColor="border300"
    padding="30px"
    margin="10px"
    {...props}
  >
    {children}
  </Container>
);

Panel.propTypes = {
  children: PropTypes.node,
};

Panel.defaultProps = {
  children: null,
};

export default Panel;
