import { gql } from '@apollo/client';

const widgetMenus = gql`
  query WidgetFragmentUserMenu(
    $variant: String!
    $appParameters: ParametersAppMenu
    $userParameters: ParametersUserMenu
  ) {
    widgetFragmentUserMenu(variant: $variant, parameters: $userParameters) {
      html
    }
    widgetFragmentAppMenu(variant: $variant, parameters: $appParameters) {
      html
    }
  }
`;

export default widgetMenus;
