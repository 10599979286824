import { gql } from '@apollo/client';

const facebookReach = gql`
  query facebookReach($address: String!, $radius: Int!) {
    facebookReach(address: $address, radius: $radius) {
      estimate
    }
  }
`;

export default facebookReach;
