import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Image from 'ls-common-client/src/components/Image';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import Dialog from 'ls-common-client/src/components/Dialog';
import AnchorLink from '../../UI/atoms/AnchorLink';
import LogoSmall from '../../../images/logoSmall.svg';

const MobileMenuDialog = ({
  menuItems,
  onClose,
  onMenuItemClick,
  ...props
}) => {
  const { pathname } = useLocation();

  return (
    <Dialog animationType="slideLeft" mode="mobile" {...props}>
      <Container display="flex" justifyContent="space-between" padding="20px">
        <Image src={LogoSmall} />
        <EmptyButton onClick={onClose}>
          <Icon
            className="ls-icon icon-generalxlarge"
            fontSize="14px"
            color="normal"
          />
        </EmptyButton>
      </Container>
      <Container padding="20px" display="flex" flexDirection="column">
        {menuItems.map(({ Icon: MenuIcon, text, path }) => (
          <AnchorLink
            key={text}
            to={path}
            onClick={onMenuItemClick}
            display="flex"
            alignItems="center"
            borderBottom={theme => `1px solid ${theme.border.border300}`}
            padding="20px"
          >
            <MenuIcon greyScale={pathname !== path} />
            <Text marginLeft="10px" fontSize="18px" color="normal">
              {text}
            </Text>
          </AnchorLink>
        ))}
      </Container>
    </Dialog>
  );
};

MobileMenuDialog.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
};

export default MobileMenuDialog;
