import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'ls-common-client/src/components/ClickOutside';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import Popup from 'ls-common-client/src/components/Popup';
import Icon from 'ls-common-client/src/components/Icon';

const ActionMenu = ({ items }, ...props) => {
  const [popupShow, setPopupShow] = useState();
  const dotColor = popupShow ? '#285aff' : '#9b9b9b';

  return (
    <ClickOutside onClickOutside={() => setPopupShow(false)} {...props}>
      <EmptyButton
        onClick={() => setPopupShow(true)}
        borderRadius="100%"
        width="34px"
        height="34px"
        border="1px solid"
        borderColor="border300"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          width="4px"
          height="4px"
          borderRadius="100%"
          backgroundColor={dotColor}
          margin="1.5px"
          display="block"
        />
        <Text
          width="4px"
          height="4px"
          borderRadius="100%"
          backgroundColor={dotColor}
          margin="1.5px"
          display="block"
        />
        <Text
          width="4px"
          height="4px"
          borderRadius="100%"
          backgroundColor={dotColor}
          margin="1.5px"
          display="block"
        />
      </EmptyButton>

      <Popup show={popupShow} anchor="topRight">
        {items.map(({ icon, text, onClick, disabled }, i) => (
          <EmptyButton
            key={text}
            disabled={disabled}
            onClick={() => {
              onClick();
              setPopupShow(false);
            }}
            fontSize="14px"
            color={disabled ? 'text300' : 'normal'}
            fontWeight="600"
            width="190px"
            padding="0 18px"
            textAlign="left"
          >
            <Text
              borderBottom={i === items.length - 1 ? 'none' : '1px solid'}
              borderColor="border300"
              display="flex"
              alignItems="center"
              width="100%"
              padding="15px 0"
            >
              <Icon
                className={`ls-icon ${icon}`}
                color={disabled ? 'text200' : 'primary'}
                fontSize="22px"
                marginRight="10px"
              />
              {text}
            </Text>
          </EmptyButton>
        ))}
      </Popup>
    </ClickOutside>
  );
};

ActionMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export default ActionMenu;
