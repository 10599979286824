import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';
import Text from 'ls-common-client/src/components/Text';
import Tag from 'ls-common-client/src/components/Tag';
import Button from 'ls-common-client/src/components/Button';
import FileUpload from 'ls-common-client/src/components/FileUpload';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import { isTouch } from 'ls-common-client/src/util';
import styled from 'styled-components';
import SearchInput from '../../UI/molecules/SearchInput';
import Panel from '../../UI/atoms/Panel';
import {
  categorySuggest as categorySuggestQuery,
  category as categoryQuery,
} from '../../../graphql/queries';
import RefreshButton from '../../UI/molecules/RefreshButton';
import SlideMenu from '../../UI/organisms/SlideMenu';
import client from '../../../services/client';
import { Context } from '../../../context/AppContext';
import ActionMenu from './ActionMenu';
import RelatedCategoriesDialog from './RelatedCategoriesDialog';
import RelatedCategories from './RelatedCategories';

const localStorageKey = 'salesToolkit.categories';

const getCategory = async id => {
  const { data: { category } = {} } = await client.query({
    query: categoryQuery,
    variables: { id },
  });
  return category;
};

const getCategories = ids => Promise.all(ids.map(id => getCategory(id)));

const removeArrayDuplicates = (array1, array2) =>
  array1.filter(({ id }) => array2.every(item => item.id !== id));

const cleanCategories = array => array.map(({ name, id }) => ({ name, id }));

const getRelatedCategories = async ids => {
  const cats = await getCategories(ids);

  return cats.reduce(
    (acc, { relatedCategories }) => [
      ...acc,
      ...removeArrayDuplicates(cleanCategories(relatedCategories), acc),
    ],
    []
  );
};

const StyledTag = styled(Tag)`
  color: ${({ theme }) => theme.text.text400};
  font-weight: 600;
  cursor: pointer;
  border-color: ${({ theme }) => theme.border.border600};
  &:hover {
    border-color: ${({ theme }) => theme.primary.primary200};
  }
`;

const Categories = props => {
  const {
    media: { desktop, mobile },
  } = useContext(Context);

  const searchInputRef = useRef();
  const autoSuggestRef = useRef();
  const fileUploadRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState();
  const [categories, setCategories] = useState(
    JSON.parse(localStorage.getItem(localStorageKey) || '[]')
  );
  const [relatedCategories, setRelatedCategories] = useState([]);
  const [relatedCategoriesLoading, setRelatedCategoriesLoading] =
    useState(false);
  const [showRelatedCategoriesDialog, setShowRelatedCategoriesDialog] =
    useState();

  const [
    getSuggestedCategories,
    { data: suggestedCategoriesData, loading: fetchLoading },
  ] = useLazyQuery(categorySuggestQuery);

  useEffect(() => {
    if (suggestedCategoriesData) {
      const {
        categorySuggest: { edges },
      } = suggestedCategoriesData;

      setResults(searchValue ? edges.map(({ node }) => node) : null);
    }
  }, [suggestedCategoriesData]);

  const fetchRelatedCategories = async () => {
    if (!categories.length) {
      setRelatedCategories([]);
      return;
    }
    const ids = categories.map(({ id }) => id);
    setRelatedCategoriesLoading(true);
    const related = await getRelatedCategories(ids);
    setRelatedCategoriesLoading(false);
    setRelatedCategories(removeArrayDuplicates(related, categories));
  };

  useEffect(() => {
    fetchRelatedCategories();
    localStorage.setItem(localStorageKey, JSON.stringify(categories));
  }, [categories]);

  const onSearchChange = ({ target: { value } }) => {
    setSearchValue(value);

    if (value) {
      getSuggestedCategories({ variables: { query: value } });
    } else {
      setResults(null);
    }
  };

  const onKeyDown = e => {
    const { which } = e;

    if (which === 40) {
      e.preventDefault();
      autoSuggestRef?.current?.firstChild.focus();
    }
  };

  const onSearchInputClear = () => {
    setSearchValue('');
    searchInputRef.current.focus();
  };

  const onCategorySelect = cat => {
    const isDuplicate = categories.some(({ id }) => cat.id === id);
    setResults(null);
    setSearchValue('');
    if (!isDuplicate) {
      setCategories([...categories, cat]);
    }
  };

  const onCategoryRemove = i => {
    setCategories(categories.filter((cat, index) => i !== index));
  };

  const onRefreshClick = () => {
    setCategories([]);
  };

  const onRelatedCategorySelect = i => {
    setRelatedCategories(
      relatedCategories.map((cat, index) =>
        index === i ? { ...cat, selected: !cat.selected } : cat
      )
    );
  };

  const onAddRelatedCategoriesClick = () => {
    const selected = relatedCategories.filter(cat => cat.selected);
    setCategories([...categories, ...selected]);
    setShowRelatedCategoriesDialog(false);
  };

  const hasSelectedRelatedCategory = useMemo(
    () => relatedCategories.some(({ selected }) => selected),
    [relatedCategories]
  );

  const onSaveClick = () => {
    const json = JSON.stringify(cleanCategories(categories));
    const blob = new Blob([json], { type: 'application/json' });
    const link = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = 'LS_categories.json';
    a.href = link;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(link);
  };

  const onOpenClick = () => {
    fileUploadRef.current.click();
  };

  const onSelected = async ([file]) => {
    const reader = new FileReader();
    reader.onload = e => {
      setCategories(JSON.parse(e.target.result));
    };
    reader.readAsText(file);
  };

  const menuItems = [
    {
      icon: 'icon-downloadcircle',
      text: 'Save',
      onClick: onSaveClick,
      disabled: !categories.length,
    },
    {
      icon: 'icon-uploadcircle',
      text: 'Open',
      onClick: onOpenClick,
    },
  ];

  return (
    <Container
      padding={desktop ? '80px 70px 20px 70px' : '80px 15px 20px 15px'}
      {...(isTouch() ? { paddingRight: '15px' } : {})}
      display="flex"
      width="100%"
      flexWrap="wrap"
      {...props}
    >
      <FileUpload
        display="none"
        ref={fileUploadRef}
        onSelected={onSelected}
        accept=".json"
      />

      {!isTouch() && (
        <SlideMenu
          items={menuItems}
          position="fixed"
          right="0"
          top={desktop ? '100px' : '160px'}
        />
      )}

      <Panel
        margin="10px"
        flex="1"
        minWidth={mobile ? '300px' : '400px'}
        display="flex"
        flexDirection="column"
      >
        <Container
          display="flex"
          marginBottom="25px"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Text
            fontWeight="600"
            fontSize="25px"
            lineHeight="1.2"
            marginRight="20px"
          >
            Search a Category Below
          </Text>
          {isTouch() && <ActionMenu items={menuItems} />}
        </Container>

        <Container display="flex">
          <Container flex="1">
            <SearchInput
              ref={searchInputRef}
              value={searchValue}
              onChange={onSearchChange}
              onKeyDown={onKeyDown}
              onClear={onSearchInputClear}
              placeholder="Search Categories"
              flex="1"
            />
            <Container position="relative" marginTop="5px" marginBottom="15px">
              <AutoSuggest
                ref={autoSuggestRef}
                data={results}
                onSelect={onCategorySelect}
                loading={fetchLoading}
                itemKey="id"
                itemDisplayText="name"
                maxHeight="255px"
              />
            </Container>
          </Container>
          <RefreshButton onClick={onRefreshClick} marginLeft="10px" />
        </Container>

        {!desktop && !!categories.length && (
          <EmptyButton
            onClick={() => setShowRelatedCategoriesDialog(true)}
            color="primary"
            display="flex"
            alignItems="center"
            fontSize="15px"
            fontWeight="600"
            marginBottom="20px"
          >
            <Icon className="ls-icon icon-plus" marginRight="5px" />
            Add Related Categories
          </EmptyButton>
        )}

        {!!categories.length && (
          <Container marginBottom="20px">
            <Text fontWeight="600" display="block" marginBottom="15px">
              Your Category Selection
            </Text>
            <Container display="flex" flexWrap="wrap" margin="0 -3px">
              {categories.map(({ name, id }, i) => (
                <StyledTag
                  key={id}
                  onClick={() => onCategoryRemove(i)}
                  margin="3px"
                  display="flex"
                  paddingLeft="6px"
                >
                  <Text
                    width="18px"
                    height="18px"
                    borderRadius="100%"
                    backgroundColor="background300"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginRight="12px"
                    flex="0 0 auto"
                  >
                    <Icon className="ls-icon icon-x" />
                  </Text>
                  {name}
                </StyledTag>
              ))}
            </Container>
          </Container>
        )}
      </Panel>
      {desktop && (
        <Panel
          margin="10px"
          flex="2"
          display="flex"
          flexDirection="column"
          minWidth="400px"
        >
          <Text
            fontWeight="600"
            fontSize="25px"
            display="block"
            marginBottom="20px"
            lineHeight="1.2"
          >
            Add Related Categories
          </Text>
          <RelatedCategories
            relatedCategories={relatedCategories}
            onAddRelatedCategoriesClick={onAddRelatedCategoriesClick}
            onRelatedCategorySelect={onRelatedCategorySelect}
            hasSelectedRelatedCategory={hasSelectedRelatedCategory}
            relatedCategoriesLoading={relatedCategoriesLoading}
            flex="1"
            marginBottom="20px"
          />
          <Container display="flex" justifyContent="flex-end">
            <Button
              onClick={onAddRelatedCategoriesClick}
              disabled={!hasSelectedRelatedCategory}
              primary
              rounded
              height="40px"
              width="auto"
              padding="0 40px"
            >
              Add
            </Button>
          </Container>
        </Panel>
      )}
      <RelatedCategoriesDialog
        relatedCategories={relatedCategories}
        onAddRelatedCategoriesClick={onAddRelatedCategoriesClick}
        onRelatedCategorySelect={onRelatedCategorySelect}
        hasSelectedRelatedCategory={hasSelectedRelatedCategory}
        show={showRelatedCategoriesDialog}
        relatedCategoriesLoading={relatedCategoriesLoading}
        onClose={() => setShowRelatedCategoriesDialog(false)}
      />
    </Container>
  );
};

export default Categories;
