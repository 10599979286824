import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from './AnchorLink';

const TabLink = ({ active, to, children, ...props }) => {
  return (
    <AnchorLink
      backgroundColor={active ? '#ececec' : 'transparent'}
      borderRadius="100px"
      padding="10px"
      fontSize="small"
      fontWeight="500"
      color="#4a4a4a"
      to={to}
      {...props}
    >
      {children}
    </AnchorLink>
  );
};

TabLink.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

TabLink.defaultProps = {
  children: null,
  active: false,
};

export default TabLink;
