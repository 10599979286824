import React, { useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import { useQuery } from '@apollo/client';
import { Context } from '../../../context/AppContext';
import auth from '../../../services/auth';
import logo from '../../../images/logo.svg';
import logoSmall from '../../../images/logoSmall.svg';
import { widgetMenus } from '../../../graphql/queries';

const Header = ({ onMenuClick, ...props }) => {
  const {
    media: { desktop },
    user: { user },
  } = useContext(Context);

  const menuIconSize = desktop ? '34px' : '30px';

  const { data: widgetMenusData, loading: loadingMenu } = useQuery(
    widgetMenus,
    {
      variables: {
        variant: 'day',
        appParameters: {
          size: '100%',
        },
        userParameters: {
          size: '100%',
          dataLayerName: 'PageDataLayer',
        },
      },
    }
  );

  const { appMenuHtml, userMenuHtml } = useMemo(() => {
    const { widgetFragmentAppMenu, widgetFragmentUserMenu } =
      widgetMenusData || {};
    return {
      userMenuHtml: widgetFragmentUserMenu?.html || '',
      appMenuHtml: widgetFragmentAppMenu?.html || '',
    };
  }, [widgetMenusData]);

  const intervalRef = useRef(0);

  useEffect(() => {
    if ((!userMenuHtml && !appMenuHtml) || !user) {
      return;
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      if (window?.lsWidget?.fragments) {
        clearInterval(intervalRef.current);
        intervalRef.current = 0;
        window.lsWidget.fragments.common.auth.setAuth({ auth, user });
      }
    }, 100);
  }, [userMenuHtml, appMenuHtml, user]);

  return (
    <Container
      padding={desktop ? '10px 20px' : '0'}
      position="fixed"
      left="0"
      top="0"
      width="100%"
      zIndex="9"
      {...props}
    >
      <Container
        borderRadius={desktop ? '30px' : '0 0 35px 35px'}
        height={desktop ? '60px' : '70px'}
        padding={desktop ? '0 30px' : '0 20px'}
        backgroundColor="rgba(255,255,255,0.5)"
        backdropFilter="blur(20px)"
        width="100%"
        boxShadow="0 2px 20px 3px rgba(0, 0, 0, 0.1)"
        display="flex"
        alignItems="center"
      >
        {!desktop && (
          <EmptyButton onClick={onMenuClick} marginRight="20px">
            <Icon
              className="ls-icon icon-menu"
              color="normal"
              fontSize="20px"
            />
          </EmptyButton>
        )}
        <Container>
          <Image src={desktop ? logo : logoSmall} />
        </Container>
        <Container display="flex" alignItems="center" marginLeft="auto">
          {!loadingMenu && appMenuHtml && (
            <Container
              dangerouslySetInnerHTML={{ __html: appMenuHtml }}
              width={menuIconSize}
              height={menuIconSize}
            />
          )}
          {!loadingMenu && userMenuHtml && (
            <Container
              dangerouslySetInnerHTML={{ __html: userMenuHtml }}
              width={menuIconSize}
              height={menuIconSize}
              marginLeft="15px"
            />
          )}
        </Container>
      </Container>
    </Container>
  );
};

Header.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
};

export default Header;
