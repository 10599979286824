import React, { useContext } from 'react';
import Text from 'ls-common-client/src/components/Text';
import Container from 'ls-common-client/src/components/Container';
import { Context } from '../../../context/AppContext';
import unauthorisedBackground from '../../../images/unauthorisedBackground.svg';

const Unauthorised = props => {
  const {
    media: { mobile },
  } = useContext(Context);

  return (
    <Container
      display="flex"
      flexDirection="column"
      width="100%"
      backgroundColor="white"
      {...props}
    >
      <Container
        backgroundImage={`url(${unauthorisedBackground})`}
        backgroundRepeat="no-repeat"
        backgroundSize="100%"
        backgroundPosition="bottom"
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Container
          maxWidth="422px"
          borderRadius="11px"
          backgroundColor="white"
          padding={mobile ? '20px' : '40px'}
          boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.2)"
          margin="20px"
        >
          <Text
            fontSize="25px"
            fontWeight="600"
            display="block"
            lineHeight="1.1"
            marginBottom="20px"
          >
            You are not authorised to view this application
          </Text>
          <Text
            color="text400"
            lineHeight="1.25"
            display="block"
            fontSize="16px"
          >
            Contact head office if you require help.
          </Text>
        </Container>
      </Container>
    </Container>
  );
};

export default Unauthorised;
