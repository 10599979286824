import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import SelectButton from 'ls-common-client/src/components/SelectButton';
import TitleToolTip from '../../../UI/molecules/TitleToolTip';

const Question = ({ text, items, selected, onSelect, required, tooltip }) => {
  return (
    <Container display="flex">
      <Container flex="1">
        <Container
          display="flex"
          alignItems="center"
          gap="8px"
          marginBottom="15px"
        >
          <Text fontWeight="450" display="block" lineHeight="1.2">
            {text} {required && <Text color="red">*</Text>}
          </Text>
          {!!tooltip && <TitleToolTip tooltip={tooltip} />}
        </Container>
        <Container display="flex" flexWrap="wrap" marginBottom="20px">
          {items.map(item => (
            <SelectButton
              key={item.value}
              onClick={() => onSelect(item.value)}
              selected={selected === item.value}
              margin="5px"
            >
              {item.text}
            </SelectButton>
          ))}
        </Container>
      </Container>
    </Container>
  );
};

const value = PropTypes.oneOfType([PropTypes.string, PropTypes.bool]);

Question.propTypes = {
  text: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: value,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: value.isRequired,
    })
  ).isRequired,
};

Question.defaultProps = {
  selected: undefined,
  required: false,
  tooltip: undefined,
};

export default Question;
