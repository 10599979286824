const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  debug: false,
  mode: 'production',
  sourceMap: 'source-map',
  hot: false,
  lsOnlineUrlRoot: 'https://www.localsearch.com.au',
  imageResizeRoot: 'https://d2xzxktm3qm7rt.cloudfront.net',
  api: 'https://services.lsapis.com.au',
  b2bApi: 'https://business.localsearch.com.au/wp-json/localsearch',
  token: {
    ...defaults.token,
    cookie: {
      domain: '.localsearch.com.au',
    },
  },
  auth: {
    ...auth,
    domain: 'auth.localsearch.com.au',
    audience: 'https://services.lsapis.com.au',
    clientID: 'aSwuHYgdT3PcOayRinZwIkPv6AbLn8Di',
    redirectUri: 'https://sales-toolkit.localsearch.com.au',
  },
  deploy: {
    domain: 'sales-toolkit.localsearch.com.au',
    subDomain: 'sales-toolkit.localsearch.com.au',
    aliases: 'sales-toolkit.localsearch.com.au',
    certificateArn:
      'arn:aws:acm:us-east-1:708891215413:certificate/fb97edb7-ae86-48e1-bc8e-0c13247a2c92',
  },
};
