import React, { forwardRef } from 'react';
import CommonInput from 'ls-common-client/src/components/Input';

const Input = forwardRef((props, ref) => (
  <CommonInput
    ref={ref}
    fontSize="16px"
    padding="0 25px 0 50px"
    textOverflow="ellipsis"
    borderRadius="8px"
    border="none"
    height="44px"
    width="100%"
    appearance="none"
    backgroundColor="background200"
    _placeholder={{
      color: 'text400',
    }}
    {...props}
  />
));

export default Input;
