import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { user as userQuery } from '../../../graphql/queries';

const useUser = () => {
  const {
    data: { currentUser: user } = {},
    loading,
    refetch,
  } = useQuery(userQuery);

  const { userRoles: { edges: roles = [] } = {} } = user || {};

  const isAuthorised = useMemo(
    () => roles.some(({ node: { roleName } }) => roleName === 'staff'),
    [roles]
  );

  return {
    user,
    loading,
    refetch,
    isAuthorised,
  };
};

export default useUser;
