import { Link } from 'react-router-dom';
import { allStyles } from 'ls-common-client/src/styleHelper2';
import styled from 'styled-components';
import styleFilter from 'ls-common-client/src/styleFilter';

const AnchorLink = styled(styleFilter(Link))`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.primary.normal};
  ${allStyles()}
`;

export default AnchorLink;
