import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Button from 'ls-common-client/src/components/Button';
import Icon from 'ls-common-client/src/components/Icon';
import RelatedCategories from '../RelatedCategories';

const RelatedCategoriesDialog = ({
  onClose,
  relatedCategories,
  onAddRelatedCategoriesClick,
  onRelatedCategorySelect,
  hasSelectedRelatedCategory,
  relatedCategoriesLoading,
  ...props
}) => {
  return (
    <Dialog
      mode="mobile"
      allowTouchMoveQuerySelectors={['.relatedCategoriesScrollContainer']}
      {...props}
    >
      <Container
        padding="30px"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Container
          display="flex"
          justifyContent="space-between"
          marginBottom="15px"
        >
          <Text fontSize="30px" lineHeight="1.2" fontWeight="600">
            Related Categories
          </Text>
          <EmptyButton onClick={onClose}>
            <Icon className="ls-icon icon-generalxlarge" color="normal" />
          </EmptyButton>
        </Container>
        <Container marginBottom="20px">
          <Text fontSize="18px" color="text400">
            Click on categories below to add them to your selection
          </Text>
        </Container>
        <RelatedCategories
          relatedCategories={relatedCategories}
          onAddRelatedCategoriesClick={onAddRelatedCategoriesClick}
          onRelatedCategorySelect={onRelatedCategorySelect}
          relatedCategoriesLoading={relatedCategoriesLoading}
          onClose={onClose}
          flex="1"
          marginBottom="20px"
        />
        <Button
          onClick={onAddRelatedCategoriesClick}
          disabled={!hasSelectedRelatedCategory}
          primary
          rounded
          height="40px"
          width="auto"
          padding="0 40px"
        >
          Add
        </Button>
      </Container>
    </Dialog>
  );
};

RelatedCategoriesDialog.propTypes = {
  relatedCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      selected: PropTypes.bool,
      id: PropTypes.string,
    })
  ).isRequired,
  onAddRelatedCategoriesClick: PropTypes.func.isRequired,
  onRelatedCategorySelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hasSelectedRelatedCategory: PropTypes.bool.isRequired,
  relatedCategoriesLoading: PropTypes.bool.isRequired,
};

export default RelatedCategoriesDialog;
