import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Container from 'ls-common-client/src/components/Container';
import { useLazyQuery } from '@apollo/client';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Text from 'ls-common-client/src/components/Text';
import Button from 'ls-common-client/src/components/Button';
import Panel from '../../../UI/atoms/Panel';
import { Context } from '../../../../context/AppContext';
import UrlForm from './UrlForm';
import KeywordRanks from './KeywordRanks';
import { seoOpportunityKeywords as seoOpportunityKeywordsQuery } from '../../../../graphql/queries';
import useWindowSize from '../../../../hooks/useWindowResize';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const OpportunityTool = props => {
  const {
    media: { mobile },
  } = useContext(Context);
  const [url, setUrl] = useState('');
  const [loadKeywords, { data, loading }] = useLazyQuery(
    seoOpportunityKeywordsQuery
  );
  const [height, setHeight] = useState('400px');
  const { winHeight } = useWindowSize();
  const refRoot = useRef();

  useEffect(() => {
    if (!refRoot.current) {
      return;
    }
    const h = winHeight - refRoot.current.getBoundingClientRect().top - 20;
    setHeight(mobile ? 'unset' : `${Math.max(400, h)}px`);
  }, [mobile, winHeight, refRoot.current]);

  const search = useCallback(
    async target => {
      setUrl(target);
      return loadKeywords({ variables: { target } });
    },
    [loadKeywords]
  );

  const { error = '', ranks = null } = useMemo(() => {
    if (loading) {
      return {};
    }
    const { seoOpportunityKeywords } = data || {};
    const { error: err = '', keywords = null } = seoOpportunityKeywords || {};
    return {
      error: err,
      ranks: keywords,
    };
  }, [data, loading]);

  const onSave = useCallback(() => {
    const { hostname } = new URL(
      url.toLowerCase().startsWith('http') ? url : `https://${url}`
    );
    const docDefinition = {
      content: [
        { text: `Rank for: ${url}`, style: 'header' },
        ' ',
        {
          style: 'defaultTable',
          table: {
            widths: ['*', 150],
            body: [
              ['Keyword', 'Rank'],
              ...ranks.map(({ keyword, rank }) => [keyword, rank]),
            ],
          },
        },
      ],
      styles: {
        defaultTable: {
          margin: [0, 10, 0, 15],
        },
      },
      defaultStyle: {},
    };
    pdfMake
      .createPdf(docDefinition)
      .download(`Rank_${hostname.replace(/\./g, '-')}.pdf`);
  }, [url, ranks]);

  return (
    <>
      <Container
        display={mobile ? 'block' : 'flex'}
        width="100%"
        padding="0"
        data-name="page-root"
        {...props}
        height={height}
        ref={refRoot}
      >
        <Panel flex="1" margin="10px">
          <UrlForm onClickLoad={search} loading={loading} />
        </Panel>
        <Panel flex="2" margin="10px">
          <KeywordRanks
            onSave={onSave}
            ranks={ranks}
            loading={loading}
            url={url}
            error={error}
          />
        </Panel>
      </Container>
      {mobile && !loading && !!url && (
        <Container
          position="fixed"
          left="0px"
          width="100%"
          bottom="0px"
          backgroundColor="normal"
        >
          <Panel
            margin="0 25px 25px 25px"
            borderTopRightRadius="0"
            borderTopLeftRadius="0"
            borderColor="white #ececec #ececec #ececec"
          >
            <Text
              color="#757575"
              display="block"
              marginBottom="16px"
              textAlign="center"
            >
              Showing {ranks.length || 0} keywords
            </Text>
            <Button
              flex="0"
              rounded
              primary
              onClick={onSave}
              disabled={!ranks.length}
            >
              Save
            </Button>
          </Panel>
        </Container>
      )}
    </>
  );
};

export default OpportunityTool;
