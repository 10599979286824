import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import styled from 'styled-components';
import cuid from 'cuid';

const StyledContainer = styled(Container)`
  background-color: #ffffff;
  border-radius: 100px;
  padding: 3px;
  display: flex;
  align-items: flex-start;
  gap: 3px;
  border: 1px solid #eaefff;
`;

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + span {
    background-color: #285aff;
    color: #ffffff;
  }
`;

const StyledSpan = styled.span`
  position: absolute;
  display: flex;
  height: 40px;
  width: 40px;
  padding: 0 1px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #9b9b9b;
`;

const data = [
  {
    text: 'SA2',
    value: 'SA2',
  },
  {
    text: 'SA3',
    value: 'SA3',
  },
];

const SASelector = ({ value, onChange, ...props }) => {
  const name = cuid();

  return (
    <StyledContainer {...props}>
      {data.map(item => (
        <StyledLabel key={item.value}>
          <StyledInput
            type="radio"
            name={name}
            value={item.value}
            checked={value === item.value}
            onChange={() => onChange(item.value)}
          />
          <StyledSpan>{item.text}</StyledSpan>
        </StyledLabel>
      ))}
    </StyledContainer>
  );
};

SASelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

SASelector.defaultProps = {
  value: 'SA2',
};

export default SASelector;
