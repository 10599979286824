import { gql } from '@apollo/client';

const seoOpportunityKeywords = gql`
  query SeoOpportunityKeywords($target: String!) {
    seoOpportunityKeywords(target: $target) {
      error
      keywords {
        keyword
        rank
      }
      target
    }
  }
`;

export default seoOpportunityKeywords;
