import React from 'react';
import PropTypes from 'prop-types';

const MenuIconSEOPackage = ({ greyScale }) => (
  <svg
    width="26"
    height="24"
    viewBox="-1 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_44_3640)">
      <path
        d="M3.72124 13.9641V12.7033C3.72025 12.5509 3.77667 12.4036 3.87942 12.291C3.98218 12.1783 4.12354 12.1085 4.27545 12.0957L5.09291 12.0244C5.21183 11.5606 5.39657 11.1159 5.64118 10.7042L5.1127 10.0748C5.01406 9.95817 4.96293 9.80873 4.96953 9.65616C4.97613 9.50359 5.03996 9.3591 5.14833 9.25156L5.97766 8.42223C6.08702 8.31617 6.2315 8.25398 6.38358 8.24739C6.53582 8.24079 6.68493 8.29044 6.80303 8.3866L7.43245 8.91507C7.84415 8.67046 8.28867 8.48589 8.75265 8.36681L8.82787 7.55133C8.83958 7.40107 8.90704 7.2607 9.01689 7.15745C9.12674 7.05419 9.27107 6.99564 9.42166 6.99316H10.6092C10.7597 6.99613 10.9038 7.05485 11.0137 7.15794C11.1234 7.26103 11.1908 7.40123 11.203 7.55133L11.2743 8.37076C11.7381 8.48969 12.1828 8.67442 12.5945 8.91903L13.2239 8.39056C13.342 8.29308 13.4916 8.24244 13.6447 8.24838C13.7976 8.25431 13.9431 8.31617 14.0532 8.42223L14.8806 9.2476C14.9895 9.35547 15.0536 9.50029 15.0602 9.65336C15.0668 9.80642 15.0154 9.95636 14.9162 10.073L14.3877 10.7024C14.6324 11.1143 14.8171 11.5588 14.936 12.0226L15.7555 12.0938C15.9082 12.1062 16.0507 12.176 16.1541 12.2891C16.2577 12.4021 16.3146 12.5501 16.3136 12.7035V13.9643C16.3136 14.0354 16.2561 14.0929 16.185 14.0929H12.1135V13.2122C12.1135 12.4615 11.713 11.7679 11.063 11.3927C10.413 11.0174 9.612 11.0174 8.96196 11.3927C8.31192 11.7679 7.91144 12.4615 7.91144 13.2122V14.0969H3.84792C3.81345 14.0964 3.78062 14.0821 3.75687 14.0572C3.73312 14.0323 3.72025 13.9988 3.72124 13.9643V13.9641Z"
        fill={greyScale ? '#9b9b9b' : '#B264FF'}
      />
      <path
        d="M2.0981 17.22H17.9326C18.3525 17.22 18.7553 17.0532 19.0522 16.7562C19.3491 16.4593 19.516 16.0565 19.516 15.6366V1.58345C19.516 1.1635 19.3493 0.760715 19.0522 0.463818C18.7553 0.166922 18.3525 0 17.9326 0H2.0981C1.67815 0 1.27536 0.166922 0.978467 0.463818C0.681405 0.760715 0.514648 1.1635 0.514648 1.58345V15.6366C0.514648 16.0565 0.681405 16.4593 0.978467 16.7562C1.27536 17.0531 1.67815 17.22 2.0981 17.22ZM17.7465 15.4386H2.29603V4.73451H17.7346L17.7465 15.4386ZM7.76288 1.58345C7.97285 1.58345 8.17425 1.66691 8.3227 1.81536C8.47115 1.96381 8.55461 2.1652 8.55461 2.37517C8.55461 2.58514 8.47115 2.78654 8.3227 2.93499C8.17425 3.08344 7.97285 3.1669 7.76288 3.1669C7.55291 3.1669 7.35152 3.0836 7.20307 2.93499C7.05462 2.78654 6.97116 2.58514 6.97116 2.37517C6.97 2.16586 7.05198 1.96463 7.19878 1.81552C7.34574 1.66641 7.54565 1.58147 7.75496 1.57949L7.76288 1.58345ZM5.39959 1.58345C5.60956 1.58345 5.81095 1.66691 5.9594 1.81536C6.10785 1.96381 6.19131 2.1652 6.19131 2.37517C6.19131 2.58514 6.10785 2.78654 5.9594 2.93499C5.81095 3.08344 5.60956 3.1669 5.39959 3.1669C5.18961 3.1669 4.98822 3.0836 4.83977 2.93499C4.69132 2.78654 4.60786 2.58514 4.60786 2.37517C4.60687 2.16586 4.68868 1.96463 4.83548 1.81552C4.98228 1.66641 5.18236 1.58147 5.3915 1.57949L5.39942 1.58345H5.39959ZM3.03629 1.58345C3.24626 1.58345 3.44766 1.66691 3.5961 1.81536C3.74455 1.96381 3.82801 2.1652 3.82801 2.37517C3.82801 2.58514 3.74472 2.78654 3.5961 2.93499C3.44766 3.08344 3.24626 3.1669 3.03629 3.1669C2.82632 3.1669 2.62492 3.0836 2.47647 2.93499C2.32803 2.78654 2.24457 2.58514 2.24457 2.37517C2.24358 2.16586 2.32539 1.96463 2.47235 1.81552C2.61931 1.66641 2.81923 1.58147 3.02837 1.57949L3.03629 1.58345Z"
        fill={greyScale ? '#c4c4c4' : '#6B92EF'}
      />
    </g>
    <defs>
      <clipPath id="clip0_44_3640">
        <rect
          width="19.0014"
          height="17.22"
          fill="white"
          transform="translate(0.514648)"
        />
      </clipPath>
    </defs>
  </svg>
);

MenuIconSEOPackage.propTypes = {
  greyScale: PropTypes.bool,
};

MenuIconSEOPackage.defaultProps = {
  greyScale: false,
};

export default MenuIconSEOPackage;
