import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Input from '../atoms/Input';

const SearchInput = forwardRef(
  (
    { placeholder, onChange, onKeyDown, value, icon, onClear, ...props },
    ref
  ) => (
    <Container position="relative" {...props}>
      <Icon
        className={`ls-icon ${icon}`}
        icon
        color="text300"
        position="absolute"
        left="20px"
        top="12px"
        fontSize="19px"
      />
      <Input
        ref={ref}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
      />
      {!!value?.length && (
        <EmptyButton
          onClick={onClear}
          height="22px"
          width="22px"
          backgroundColor="background300"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          right="10px"
          borderRadius="100%"
          top="50%"
          transform="translateY(-50%)"
        >
          <Icon
            className="ls-icon icon-generalxlarge"
            color="normal"
            fontSize="8px"
          />
        </EmptyButton>
      )}
    </Container>
  )
);

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onKeyDown: PropTypes.func,
};

SearchInput.defaultProps = {
  placeholder: '',
  value: '',
  icon: 'icon-search',
  onChange: () => null,
  onClear: () => null,
  onKeyDown: () => null,
};

export default SearchInput;
