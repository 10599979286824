const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  lsOnlineUrlRoot: 'https://staging.ls-www.localsearch.cloud',
  imageResizeRoot: 'https://dkw8fcvpqki80.cloudfront.net',
  api: 'https://staging.services.lsapis.com.au',
  auth: {
    ...auth,
    domain: 'staging.auth.localsearch.cloud',
    audience: 'https://services.lsapis.com.au',
    clientID: 'UOSo9kZyffn65QqshjN9tGJiNOBTqjYE',
    redirectUri: 'https://staging.ls-sales-toolkit.localsearch.cloud',
  },
  deploy: {
    domain: 'ls-sales-toolkit.localsearch.cloud',
    subDomain: 'staging.ls-sales-toolkit.localsearch.cloud',
    aliases: 'staging.ls-sales-toolkit.localsearch.cloud',
    certificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/52ee85f9-aed8-4ced-88ea-b40500f24049',
  },
};
