import React, {
  useContext,
  useEffect,
  useCallback,
  useRef,
  useState,
} from 'react';
import mapboxgl from 'mapbox-gl';
import Map from 'ls-map';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import { isTouch } from 'ls-common-client/src/util';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';
import Loader from 'ls-common-client/src/components/Loader';
import Text from 'ls-common-client/src/components/Text';
import SearchInput from '../../UI/molecules/SearchInput';
import SlideMenu from '../../UI/organisms/SlideMenu';
import { Context } from '../../../context/AppContext';
import { searchAreaSuggest as searchAreaSuggestQuery } from '../../../graphql/queries';
import SearchAreaDialog from './SearchAreaDialog';
import SASelector from './SASelector';

const defaultCoords = [134.489563, -25.734968];

const StyledContainer = styled(Container)`
  & .statistical-area-plugin-tooltip {
    font-family: Circular;
    box-shadow: 0 -2px 25px 0 rgba(0, 29, 125, 0.16);
  }
`;

const getUserCoords = () =>
  new Promise(resolve => {
    const success = position => {
      const {
        coords: { latitude, longitude },
      } = position;
      resolve([longitude, latitude]);
    };

    const fail = () => {
      resolve(null);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, fail);
    }
  });

const Home = () => {
  const {
    media: { mobile, desktop },
  } = useContext(Context);

  const mapContainerRef = useRef();
  const autoSuggestRef = useRef();
  const map = useRef();
  const statisticalAreas = useRef();
  const utils = useRef();
  const searchInputRef = useRef();
  const [statisticalAreaCount, setStatisticalAreaCount] = useState(0);
  const [populationCount, setPopulationCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchAreaResults, setSearchAreaResults] = useState(null);
  const [userCoords, setUserCoords] = useState();
  const [searchAreaData, setSearchAreaData] = useState();
  const [statisticalAreasData, setStatisticalAreasData] = useState([]);
  const [mapLoading, setMapLoading] = useState(true);
  const [searchAreaLoading, setSearchAreaLoading] = useState(false);
  const [showServiceAreaDialog, setShowServiceAreaDialog] = useState();
  const [selectionMode, setSelectionMode] = useState('SA2');

  const getSelectedPopulation = () => {
    const selectedIds = statisticalAreas.current.getSelected();
    const selectedData = statisticalAreas.current.queryAll(selectedIds);
    return selectedData
      .reduce((agg, { population }) => agg + population, 0)
      .toLocaleString();
  };

  useEffect(() => {
    map.current = Map.create({
      container: mapContainerRef.current,
    });

    statisticalAreas.current = Map.plugins.statisticalAreaPlugin.create(
      map.current,
      {
        tooltipHTML: ({ id }) => {
          const { name, population } = statisticalAreas.current.query(id);
          return `<strong>${name}</strong> <br /> Population: ${
            population === null || population === undefined
              ? 'unknown'
              : population.toLocaleString()
          }`;
        },
      }
    );

    utils.current = Map.plugins.utilPlugin.create(map);

    if (!isTouch()) {
      map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    }
    map.current.addControl(new mapboxgl.ScaleControl(), 'bottom-left');

    (async () => {
      const coords = await getUserCoords();
      setMapLoading(false);
      setUserCoords(coords);
      map.current.setCenter(coords || defaultCoords);
      map.current.setZoom(coords ? 10 : 5);
    })();

    return () => map.current.remove();
  }, []);

  const getSiblingIds = id => {
    const parentId = id.substring(0, 5);
    const siblings = statisticalAreas.current.queryAll([parentId], {
      match: 'prefix',
    });
    return siblings.map(item => item.id);
  };

  const onStatisticalAreaClick = useCallback(
    id => {
      const ids = selectionMode === 'SA3' ? getSiblingIds(id) : [id];
      const isSelected = ids.every(val =>
        statisticalAreas.current.selectedExists(val)
      );

      if (!isSelected) {
        ids.forEach(val => {
          statisticalAreas.current.addSelected(val);
        });
      } else {
        ids.forEach(val => {
          statisticalAreas.current.removeSelected(val);
        });
      }
      setStatisticalAreaCount(statisticalAreas.current.getSelected().length);
      setPopulationCount(getSelectedPopulation());
    },
    [selectionMode]
  );

  useEffect(() => {
    if (!statisticalAreas.current) {
      return null;
    }

    const handler = onStatisticalAreaClick;
    statisticalAreas.current.on('click', handler);

    return () => statisticalAreas.current.off('click', handler);
  }, [statisticalAreas.current, onStatisticalAreaClick]);

  const [getSearchAreas, { data }] = useLazyQuery(searchAreaSuggestQuery);

  useEffect(() => {
    if (data) {
      const { searchAreaSuggest: { edges = [] } = {} } = data || {};
      setSearchAreaResults(searchValue ? edges.map(({ node }) => node) : null);
    }
  }, [data]);

  const onSearchChange = async ({ target: { value } }) => {
    setSearchValue(value);
    setSearchAreaLoading(true);

    if (value) {
      await getSearchAreas({ variables: { query: value } });
    } else {
      setSearchAreaResults(null);
    }

    setSearchAreaLoading(false);
  };

  const onKeyDown = e => {
    const { which } = e;

    if (which === 40) {
      e.preventDefault();
      autoSuggestRef?.current?.firstChild.focus();
    }
  };

  const refresh = () => {
    map.current.setCenter(userCoords || defaultCoords);
    map.current.setZoom(userCoords ? 10 : 5);
    setStatisticalAreaCount(0);
    setPopulationCount(0);
    setSearchValue('');
    setSearchAreaData(null);
    setStatisticalAreasData([]);
    statisticalAreas.current.clearSelected();
  };

  const onSearchInputClear = () => {
    setSearchValue('');
    searchInputRef.current.focus();
    refresh();
  };

  const setZoom = () => {
    const bboxes = statisticalAreas.current
      .getSelected()
      .map(id => statisticalAreas.current.query(id).bbox);
    const bbox = utils.current.mergeBBoxes(bboxes);
    map.current.fitBounds(bbox);
  };

  const onSelect = selected => {
    statisticalAreas.current.clearSelected();
    selected.statisticalAreas.forEach(({ id }) => {
      statisticalAreas.current.addSelected(id);
    });
    setStatisticalAreaCount(statisticalAreas.current.getSelected().length);
    setPopulationCount(getSelectedPopulation());
    setSearchValue(selected.name);
    setSearchAreaResults(null);
    setSearchAreaData(selected);
    setZoom();
  };

  const onServiceAreaInfoClick = () => {
    if (searchAreaData) {
      setStatisticalAreasData(
        statisticalAreas.current.queryAll(
          statisticalAreas.current.getSelected()
        )
      );
      setShowServiceAreaDialog(true);
    }
  };

  const onRemoveStatisticalArea = id => {
    statisticalAreas.current.removeSelected(id);
    setStatisticalAreasData(
      statisticalAreas.current.queryAll(statisticalAreas.current.getSelected())
    );
    setStatisticalAreaCount(statisticalAreas.current.getSelected().length);
    setPopulationCount(getSelectedPopulation());
  };

  return (
    <>
      <StyledContainer ref={mapContainerRef} width="100%" />
      <Container
        opacity={mapLoading ? 1 : 0}
        transition="all 2s ease"
        position="absolute"
        backgroundColor="rgba(255,255,255,0.85)"
        left="0"
        top="0"
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        pointerEvents="none"
      >
        <Loader width="200px" />
      </Container>
      <Container
        position="fixed"
        top={desktop ? '18px' : '80px'}
        zIndex={desktop ? '9' : '7'}
        left="50%"
        transform="translateX(-50%)"
        display="flex"
        maxWidth={desktop ? '405px' : 'unset'}
        width="100%"
        flex="1"
        padding="0 20px"
        alignItems="center"
      >
        <Container position="relative" flex="1">
          <SearchInput
            ref={searchInputRef}
            onChange={onSearchChange}
            onKeyDown={onKeyDown}
            onClear={onSearchInputClear}
            value={searchValue}
            placeholder="Search Statistical Areas"
          />
          <Container>
            <AutoSuggest
              ref={autoSuggestRef}
              data={searchAreaResults}
              loading={searchAreaLoading}
              onSelect={onSelect}
              itemDisplayText="description"
              itemKey="id"
              marginTop="10px"
            />
          </Container>
        </Container>
        <SASelector
          value={selectionMode}
          onChange={setSelectionMode}
          marginLeft="15px"
        />
      </Container>

      {mobile ? (
        <Container
          position="fixed"
          bottom="50px"
          left="0"
          right={isTouch() ? '0' : '40px'}
          zIndex="2"
          padding="20px"
          display="flex"
        >
          <Container
            flex="1"
            backgroundColor="white"
            borderRadius="20px"
            boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.2)"
            border={theme => `1px solid ${theme.border.border300}`}
          >
            <Container
              display="flex"
              alignItems="stretch"
              borderBottom={theme => `1px solid ${theme.border.border300}`}
            >
              <Text
                fontWeight="600"
                fontSize="16px"
                padding="12px 15px"
                borderRight={theme => `1px solid ${theme.border.border300}`}
                flex="1"
                lineHeight="1.2"
              >
                Statistical Areas
              </Text>
              <Text
                flex="1"
                display="flex"
                alignItems="center"
                padding="12px 15px"
                fontSize="16px"
                color="text300"
                fontWeight="600"
              >
                {statisticalAreaCount}
              </Text>
            </Container>
            <Container display="flex" alignItems="stretch">
              <Text
                fontWeight="600"
                fontSize="16px"
                padding="12px 15px"
                borderRight={theme => `1px solid ${theme.border.border300}`}
                flex="1"
                lineHeight="1.2"
              >
                Population Targeted
              </Text>
              <Text
                flex="1"
                display="flex"
                alignItems="center"
                padding="12px 15px"
                fontSize="16px"
                color="text300"
                fontWeight="600"
              >
                {populationCount}
              </Text>
            </Container>
          </Container>
        </Container>
      ) : (
        <Container
          position="fixed"
          bottom="28px"
          right={isTouch() ? '0' : '50px'}
          marginLeft="120px"
          zIndex="2"
          display="flex"
        >
          <Container
            backgroundColor="white"
            borderRadius="11px"
            boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.2)"
            display="flex"
            alignItems="stretch"
            padding="9px 20px"
            height="49px"
            marginRight="10px"
          >
            <Text
              borderRight={theme => `1px solid ${theme.border.border300}`}
              paddingRight="15px"
              marginRight="15px"
              display="flex"
              alignItems="center"
              fontWeight="600"
              fontSize="16px"
            >
              Statistical Areas
            </Text>
            <Text
              display="flex"
              alignItems="center"
              color="text400"
              fontWeight="600"
            >
              {statisticalAreaCount}
            </Text>
          </Container>
          <Container
            backgroundColor="white"
            borderRadius="11px"
            boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.2)"
            display="flex"
            alignItems="stretch"
            padding="9px 20px"
            height="49px"
            marginRight="10px"
          >
            <Text
              borderRight={theme => `1px solid ${theme.border.border300}`}
              paddingRight="15px"
              marginRight="15px"
              display="flex"
              alignItems="center"
              fontWeight="600"
              fontSize="16px"
            >
              Population Targeted
            </Text>
            <Text
              display="flex"
              alignItems="center"
              color="text400"
              fontWeight="600"
            >
              {populationCount}
            </Text>
          </Container>
        </Container>
      )}

      <SlideMenu
        items={[
          {
            icon: 'icon-info',
            text: 'Service Area Info',
            onClick: onServiceAreaInfoClick,
            disabled: !searchAreaData,
          },
        ]}
        position="fixed"
        right="0"
        top={desktop ? '110px' : '160px'}
      />

      <SearchAreaDialog
        show={showServiceAreaDialog}
        onClose={() => setShowServiceAreaDialog(false)}
        onRemoveStatisticalArea={onRemoveStatisticalArea}
        searchAreaData={searchAreaData}
        statisticalAreasData={statisticalAreasData}
      />
    </>
  );
};

export default Home;
