import { gql } from '@apollo/client';

const googlePackageLocationSuggest = gql`
  query googlePackageLocationSuggest($query: String!) {
    googlePackageLocationSuggest(query: $query) {
      id
      name
      targetType
      reach
    }
  }
`;

export default googlePackageLocationSuggest;
