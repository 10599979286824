import React from 'react';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';

const RefreshButton = props => (
  <EmptyButton
    width="44px"
    height="44px"
    boxShadow="0 0 7px 0 rgba(0, 0, 0, 0.1)"
    backgroundColor="white"
    borderRadius="100%"
    {...props}
  >
    <Icon
      className="ls-icon icon-generalrefresh"
      fontSize="17px"
      color="normal"
    />
  </EmptyButton>
);

export default RefreshButton;
