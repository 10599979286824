import { create } from 'ls-common-client/src/auth';
import config from '../config';
import logger from './logger';

const { auth, token } = config;

const instance = create({
  auth,
  token,
  logger,
});

export default instance;
