import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Button from 'ls-common-client/src/components/Button';
import Loader from 'ls-common-client/src/components/Loader';
import Icon from 'ls-common-client/src/components/Icon';
import Validator from 'ls-common-client/src/components/Validator';
import { isTouch } from 'ls-common-client/src/util';
import Input from '../../../UI/atoms/Input';
import Panel from '../../../UI/atoms/Panel';
import LocationSuggest from './LocationSuggest';
import googlePackage from '../../../../images/googlePackage.svg';
import { Context } from '../../../../context/AppContext';
import { googlePackageCalculate as googlePackageCalculateQuery } from '../../../../graphql/queries';

const PackageCalculator = props => {
  const {
    media: { desktop, mobile },
  } = useContext(Context);

  const [location, setLocation] = useState();
  const [keyword, setKeyword] = useState();
  const [keywordDirty, setKeywordDirty] = useState();
  const [keywordValid, setKeywordValid] = useState();
  const [valid, setValid] = useState();
  const [results, setResults] = useState();

  const [calculate, { data, loading }] = useLazyQuery(
    googlePackageCalculateQuery
  );

  useEffect(() => {
    if (data) {
      const { googlePackageCalculate: result } = data;
      setResults(result);
    }
  }, [data]);

  const onKeywordChange = input => {
    const val = input.target.value;
    const isValid = val.split(' ').filter(Boolean).length <= 3;
    setKeywordValid(isValid);
    setKeywordDirty(true);
    setKeyword(isValid ? val.trim() : undefined);
  };

  const onSubmit = useCallback(() => {
    const isValid = location && keyword;
    setValid(isValid);

    if (!isValid) {
      return;
    }

    calculate({
      variables: {
        keyword,
        locationId: location.id,
      },
    });
  }, [location, keyword]);

  return (
    <Container
      padding={desktop ? '80px 10px 20px 70px' : '80px 15px 20px 15px'}
      {...(isTouch() ? { paddingRight: '15px' } : {})}
      display="flex"
      width="100%"
      flexWrap="wrap"
      {...props}
    >
      <Panel
        margin="10px"
        flex="1"
        minWidth={mobile ? '300px' : '400px'}
        display="flex"
        flexDirection="column"
      >
        <Container
          display="flex"
          marginBottom="25px"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Text
            fontWeight="600"
            fontSize="25px"
            lineHeight="1.2"
            marginRight="20px"
            marginBottom="20px"
          >
            Google Ads Package Calculator
          </Text>
        </Container>
        <Container display="flex">
          <Container flex="1">
            <Text
              fontWeight="600"
              display="block"
              marginBottom="15px"
              lineHeight="1.2"
            >
              Location
            </Text>
            <LocationSuggest onSelect={setLocation} />
          </Container>
        </Container>
        <Container display="flex">
          <Container flex="1">
            <Text
              fontWeight="600"
              display="block"
              lineHeight="1.2"
              marginBottom="15px"
            >
              Service
            </Text>
            <Container position="relative">
              <Icon
                className="ls-icon icon-search"
                icon
                color="text300"
                position="absolute"
                left="20px"
                top="12px"
                fontSize="19px"
              />
              <Input
                onChange={onKeywordChange}
                placeholder="Search service keyword..."
              />
            </Container>
            <Validator
              display="block"
              marginBottom="15px"
              style={{
                visibility:
                  keywordDirty && !keywordValid ? 'visible' : 'hidden',
              }}
            >
              Please try another keyword
            </Validator>
          </Container>
        </Container>
        <Container display="flex">
          <Container>
            <Button rounded primary margin onClick={onSubmit}>
              Submit
            </Button>
          </Container>
        </Container>
      </Panel>
      <Panel
        margin="10px"
        flex="2"
        display="flex"
        flexDirection="column"
        minWidth={mobile ? '300px' : '400px'}
        alignItems="center"
        background="radial-gradient(111.51% 101.35% at -4.01% 4.24%, #66BCEF 39.06%, #5C92F3 70.83%)"
      >
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          flexDirection="column"
          color="#fff"
        >
          <Container marginBottom="40px" marginTop={mobile ? '10px' : '180px'}>
            <Container
              backgroundColor="#fff"
              height="142px"
              width="142px"
              borderRadius="500px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image src={googlePackage} width="50px" height="50px" />
            </Container>
          </Container>
          <Container flex="1" minHeight="180px">
            <Container
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {!valid && <Text>Start searching to see results.</Text>}
              {valid && loading && (
                <Container>
                  <Text>Your Google Ads package:</Text>
                  <Container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="112px"
                  >
                    <Loader width="200px" />
                  </Container>
                </Container>
              )}
              {valid && !loading && results?.package && (
                <Container
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Text>Your Google Ads package:</Text>
                  <Container
                    display="flex"
                    alignItems="center"
                    marginTop="10px"
                  >
                    <Text fontSize="42px" fontWeight="600">
                      ${Math.ceil((results?.package || 0) / 100) * 100}
                    </Text>
                    &nbsp;
                    <Text>/ per month </Text>
                  </Container>
                </Container>
              )}
              {valid && !loading && !results?.package && (
                <Container
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Text>Your Google Ads package:</Text>
                  <Text fontSize="18px" marginTop="10px" fontWeight="600">
                    No Results. Please try a broader service keyword, e.g.
                    plumber, florist, dentist
                  </Text>
                </Container>
              )}
            </Container>
          </Container>
        </Container>
      </Panel>
    </Container>
  );
};

export default PackageCalculator;
