import React from 'react';
import ReactDom from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { hot } from 'react-hot-loader/root';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import App from './components/App';
import logger from './services/logger';
import auth from './services/auth';
import client from './services/client';
import config from './config';

const { googleMapsApiKey } = config;

logger.info(config);

const Root = () => (
  <ApolloProvider client={client}>
    <HelmetProvider>
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
          type="text/javascript"
        />
      </Helmet>
      <App />
    </HelmetProvider>
  </ApolloProvider>
);

const render = async () => {
  const token = await auth.init();
  if (token) {
    ReactDom.render(<Root />, document.getElementById('root'));
  }
};

render();

export default hot(render);
