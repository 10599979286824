import { gql } from '@apollo/client';

const googlePackageCalculate = gql`
  query googlePackageCalculate($keyword: String!, $locationId: String!) {
    googlePackageCalculate(keyword: $keyword, locationId: $locationId) {
      actual
      package
      metrics
    }
  }
`;

export default googlePackageCalculate;
