import React, { useContext } from 'react';
import Text from 'ls-common-client/src/components/Text';
import Container from 'ls-common-client/src/components/Container';
import AnchorLink from '../UI/atoms/AnchorLink';
import notFoundBackground from '../../images/notFoundBackground.png';
import { Context } from '../../context/AppContext';

const NotFound = () => {
  const {
    media: { mobile },
  } = useContext(Context);

  return (
    <Container
      backgroundColor="#222e35"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container
        maxWidth="1000px"
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${notFoundBackground})`}
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        backgroundPosition="center"
      >
        <Container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="30px"
        >
          <Text
            color="white"
            fontSize="24px"
            textShadow="0 0 21px rgba(0, 0, 0, 0.5)"
            lineHeight="0.8"
          >
            Oops!
          </Text>
          <Text
            color="white"
            fontSize={mobile ? '150px' : '290px'}
            fontWeight="bold"
            textShadow="0 0 21px rgba(0, 0, 0, 0.5)"
            lineHeight="1"
            marginBottom="70px"
          >
            404
          </Text>
          <Text
            color="white"
            fontSize="30px"
            textShadow="0 0 21px rgba(0, 0, 0, 0.5)"
            lineHeight="0.8"
            marginBottom="50px"
          >
            Page not found.
          </Text>
          <AnchorLink
            to="/"
            padding="12px 15px"
            borderRadius="30px"
            backgroundColor="white"
            color="text700"
            maxWidth="182px"
            width="100%"
            textAlign="center"
            fontWeight="600"
          >
            Back to Home
          </AnchorLink>
        </Container>
      </Container>
    </Container>
  );
};

export default NotFound;
