import { gql } from '@apollo/client';

const searchAreaSuggest = gql`
  query searchAreaSuggest($query: String!) {
    searchAreaSuggest(query: $query) {
      edges {
        node {
          id
          name
          coordinates
          description
          sort
          statisticalAreas {
            id
          }
        }
      }
    }
  }
`;

export default searchAreaSuggest;
