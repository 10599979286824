import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import * as theme from 'ls-common-client/src/themes/default';
import { Provider } from '../context/AppContext';
import Layout from './templates/Layout';
import Home from './pages/Home';
import Categories from './pages/Categories';
import GoogleAdsPackageCalculator from './pages/GoogleAds/PackageCalculator';
import SEO from './pages/SEO';
// import FacebookReachCalculator from './pages/Facebook/ReachCalculator';
import NotFound from './pages/NotFound';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    font-family: ${props => props.theme.font.circular};
    font-size: ${props => props.theme.fontSize.normal};
    color: ${props => props.theme.text.normal};
    height: 1px;
    min-height: 100%;
    background-color: ${props => props.theme.background.normal};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    min-height: 100vh;
  }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  button {
    font-family: Circular;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
`;

const App = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route
          path="/"
          element={
            <Provider>
              <Layout />
            </Provider>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="categories" element={<Categories />} />
          <Route
            path="google/packageCalculator"
            element={<GoogleAdsPackageCalculator />}
          />
          <Route path="seo" element={<SEO />}>
            <Route path=":tool" element={<SEO />} />
          </Route>
          <Route
            path="facebook/reachCalculator"
            element={/* <FacebookReachCalculator /> */ <Navigate to="/" />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
