import React, { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import Loader from 'ls-common-client/src/components/Loader';
import logo from '../../../images/logo.svg';
import { Context } from '../../../context/AppContext';
import Unauthorised from './Unauthorised';
import Header from './Header';
import DesktopMenu from './DesktopMenu';
import MenuIconCategories from '../../../images/MenuIconCategories';
import MenuIconMap from '../../../images/MenuIconMap';
// import MenuIconGooglePackage from '../../../images/MenuItemGooglePackage';
import MenuIconSEOPackage from '../../../images/MenuItemSEOPackage';
// import MenuIconFacebookReach from '../../../images/MenuItemFacebookReach';
import MobileMenuDialog from './MobileMenuDialog';

const menuItems = [
  {
    Icon: MenuIconMap,
    text: 'Map Builder',
    path: '/',
    routes: ['/'],
  },
  {
    Icon: MenuIconCategories,
    text: 'Categories',
    path: '/categories',
    routes: ['/categories'],
  },
  /*
  // broken for now. waiting new user credential
  {
    Icon: MenuIconGooglePackage,
    text: 'Package Tool',
    path: '/google/packageCalculator',
  },
  */
  {
    Icon: MenuIconSEOPackage,
    text: 'SEO Tool',
    path: '/seo/opportunityTool',
    routes: ['/seo/opportunityTool', '/seo/packageCalculator'],
  },
  /*
  // hide by AWR7601
  {
    Icon: MenuIconFacebookReach,
    text: 'Facebook Reach Calculator',
    path: '/facebook/reachCalculator',
    routes: ['/facebook/reachCalculator'],
  },
  */
];

const Layout = () => {
  const {
    user: { loading, isAuthorised },
    media: { desktop },
  } = useContext(Context);

  const [showMobileMenuDialog, setShowMobileMenuDialog] = useState();

  return (
    <Container display="flex" minHeight="100vh">
      <Container
        position="fixed"
        pointerEvents="none"
        opacity={loading ? 1 : 0}
        left="0"
        top="0"
        width="100%"
        height="100%"
        backgroundColor="white"
        transition="all 0.6s ease"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        zIndex="15"
      >
        <Image width="200px" src={logo} />
        <Loader width="200px" />
      </Container>

      <Header onMenuClick={() => setShowMobileMenuDialog(true)} />

      {desktop ? (
        <DesktopMenu
          menuItems={menuItems}
          position="fixed"
          left="0"
          top="100px"
          zIndex="1"
        />
      ) : (
        <MobileMenuDialog
          show={showMobileMenuDialog}
          menuItems={menuItems}
          onClose={() => setShowMobileMenuDialog(false)}
          onMenuItemClick={() => setShowMobileMenuDialog(false)}
        />
      )}

      {isAuthorised ? <Outlet /> : <Unauthorised />}
    </Container>
  );
};

export default Layout;
