const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  lsOnlineUrlRoot: 'https://preprod.ls-www.localsearch.cloud',
  imageResizeRoot: 'https://d7dgpygyd9llm.cloudfront.net',
  api: 'https://preprod.services.lsapis.com.au',
  auth: {
    ...auth,
    domain: 'preprod.auth.localsearch.cloud',
    audience: 'https://services.lsapis.com.au',
    clientID: 'VxwKetAfs2LQRComUuYEf3gAL67IvztM',
    redirectUri: 'https://preprod.ls-business-profile.localsearch.cloud',
  },
  deploy: {
    domain: 'preprod.ls-business-profile.localsearch.cloud',
    subDomain: 'preprod.ls-business-profile.localsearch.cloud',
    aliases: 'preprod.ls-business-profile.localsearch.cloud',
    certificateArn:
      'arn:aws:acm:us-east-1:855737094537:certificate/0be56b54-7b29-4fe9-9186-aa21b0308e71',
  },
};
