import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Table from 'ls-common-client/src/components/Table';
import TableCell from 'ls-common-client/src/components/TableCell';
import CommonTableHeaderCell from 'ls-common-client/src/components/TableHeaderCell';
import Button from 'ls-common-client/src/components/Button';
import styled from 'styled-components';
import Loader from 'ls-common-client/src/components/Loader';
import seoSearchToSee from '../../../../images/seoSearchToSee.svg';
import seoExclamation from '../../../../images/seoExclamation.svg';
import { Context } from '../../../../context/AppContext';

const BorderedCell = styled(TableCell)`
  border-bottom: 1px solid #efefef;
  border-left: none;
  &:first-child {
    border-bottom: 1px solid #efefef;
  }
`;
const TableHeaderCell = styled(CommonTableHeaderCell)`
  border-left: none;
`;
const StickyTable = styled(Table)`
  position: sticky;
  top: 70px;
`;

const KeywordRanks = ({ url, ranks, loading, error, onSave, ...rest }) => {
  const {
    media: { mobile },
  } = useContext(Context);
  const keywords = useMemo(
    () =>
      !ranks
        ? []
        : [...ranks].sort(
            ({ keyword: kw1, rank: rank1 }, { keyword: kw2, rank: rank2 }) =>
              rank1 === rank2 ? kw1.localeCompare(kw2) : rank1 - rank2
          ),
    [ranks]
  );

  const isWaiting = !url && !error;
  const isEmptyResult =
    !loading && !isWaiting && keywords.length === 0 && !error;

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      flex="1 0 0"
      alignSelf="stretch"
      {...rest}
      {...(mobile ? {} : { height: '100%' })}
    >
      {(loading || isWaiting || isEmptyResult || error) && (
        <Container flex="1 0 0" alignSelf="stretch" margin="55px 0">
          <Container position="relative" textAlign="center">
            <Image src={seoSearchToSee} width="142px" height="142px" />
            {!isEmptyResult && !loading && (
              <Image
                src={seoExclamation}
                position="absolute"
                width="41px"
                height="41px"
                transform="translate(-100%, 100px)"
              />
            )}
            <Container color="#757575" marginTop="40px" textAlign="center">
              {isWaiting && <Text>Search to see results.</Text>}
              {isEmptyResult && <Text>There are no results.</Text>}
              {loading && <Loader width="200px" margin="0 auto" />}
              {error && !loading && (
                <Text color={theme => theme.error.normal}>{error}</Text>
              )}
            </Container>
          </Container>
        </Container>
      )}
      {keywords.length > 0 && (
        <>
          {!mobile && (
            <Container width="100%">
              <Table>
                <thead>
                  <tr>
                    <TableHeaderCell textAlign="left">Keywords</TableHeaderCell>
                    <TableHeaderCell textAlign="center" width="34%">
                      Rank
                    </TableHeaderCell>
                  </tr>
                </thead>
              </Table>
            </Container>
          )}
          <Container
            width="100%"
            marginBottom="40px"
            paddingBottom={mobile ? '80px' : 0}
            {...(mobile
              ? {}
              : {
                  overflow: 'auto',
                  flex: '1',
                })}
          >
            {mobile && (
              <StickyTable>
                <thead>
                  <tr>
                    <TableHeaderCell textAlign="left">Keywords</TableHeaderCell>
                    <TableHeaderCell textAlign="center" width="34%">
                      Rank
                    </TableHeaderCell>
                  </tr>
                </thead>
              </StickyTable>
            )}
            <Table>
              <tbody>
                {keywords.map(({ keyword, rank }) => (
                  <tr key={keyword}>
                    <BorderedCell>{keyword}</BorderedCell>
                    <BorderedCell textAlign="center" width="34%">
                      {rank}
                    </BorderedCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
          {!mobile && (
            <Container display="flex" alignSelf="stretch" alignItems="center">
              <Text flex="1" color="#757575" padding="0 16px">
                Showing {keywords.length || 0} keywords
              </Text>
              <Button
                flex="0"
                rounded
                primary
                onClick={onSave}
                disabled={!keywords.length}
              >
                Save
              </Button>
            </Container>
          )}
        </>
      )}
    </Container>
  );
};

KeywordRanks.propTypes = {
  ranks: PropTypes.arrayOf(
    PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      rank: PropTypes.number.isRequired,
    })
  ),
  url: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

KeywordRanks.defaultProps = {
  ranks: null,
  url: '',
  loading: false,
  error: '',
};

export default KeywordRanks;
