import { gql } from '@apollo/client';

const seoPackageCalculate = gql`
  query seoPackageCalculate(
    $region: String!
    $locationQuantity: String!
    $multi: Boolean!
    $ymyl: Boolean!
    $competition: String!
  ) {
    seoPackageCalculate(
      region: $region
      locationQuantity: $locationQuantity
      multi: $multi
      ymyl: $ymyl
      competition: $competition
    ) {
      package
    }
  }
`;

export default seoPackageCalculate;
