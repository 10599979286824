import React, { useContext, useMemo } from 'react';
import Container from 'ls-common-client/src/components/Container';
import { isTouch } from 'ls-common-client/src/util';
import { useParams } from 'react-router-dom';
import TabLink from '../../UI/atoms/TabLink';
import PackageCalculator from './PackageCalculator';
import OpportunityTool from './OpportunityTool';
import { Context } from '../../../context/AppContext';

const tabs = [
  {
    id: 'opportunityTool',
    text: 'SEO Opportunity Tool',
    component: OpportunityTool,
    hidden: true,
  },
  {
    id: 'packageCalculator',
    text: 'SEO Calculator',
    component: PackageCalculator,
  },
].filter(({ hidden }) => !hidden);

const SEO = props => {
  const {
    media: { desktop },
  } = useContext(Context);

  const { tool } = useParams();
  const { id: activeId, component: ActiveComponent } = useMemo(() => {
    const i = Math.max(
      0,
      tabs.findIndex(({ id }) => id === tool)
    );
    return tabs[i];
  }, [tool]);

  return (
    <Container
      padding={desktop ? '80px 10px 20px 70px' : '80px 15px 20px 15px'}
      {...(isTouch() ? { paddingRight: '15px' } : {})}
      width="100%"
      {...props}
    >
      {tabs.length > 1 && (
        <Container
          display="flex"
          flexWrap="wrap"
          margin="20px 10px 10px 10px"
          gap="10px"
        >
          {tabs.map(({ text, id }) => (
            <TabLink key={id} active={id === activeId} to={`/seo/${id}`}>
              {text}
            </TabLink>
          ))}
        </Container>
      )}
      <ActiveComponent {...(tabs.length > 1 ? {} : { marginTop: '10px' })} />
    </Container>
  );
};

export default SEO;
