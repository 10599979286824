import React from 'react';
import PropTypes from 'prop-types';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import Container from 'ls-common-client/src/components/Container';

const SlideMenu = ({ items, ...props }) => (
  <Container
    display="flex"
    flexDirection="column"
    alignItems="flex-end"
    {...props}
  >
    {items.map(({ onClick, icon, text, disabled }) => {
      if (disabled) {
        return null;
      }
      return (
        <EmptyButton
          key={text}
          onClick={onClick}
          transform="translateX(calc(100% - 55px))"
          marginBottom="10px"
          backgroundColor="white"
          boxShadow="0 2px 10px 0 rgba(0, 0, 0, 0.2)"
          border={theme => `1px solid ${theme.border.border200}`}
          display="flex"
          alignItems="center"
          height="44px"
          padding="0 15px"
          borderRadius="30px 0 0 30px"
          color="text700"
          transition="all ease 0.3s"
          _hover={{
            transform: 'translateX(0)',
          }}
        >
          <Icon
            className={`ls-icon ${icon}`}
            fontSize="25px"
            marginRight="15px"
          />
          <Text fontSize="14px" fontWeight="600">
            {text}
          </Text>
        </EmptyButton>
      );
    })}
  </Container>
);

SlideMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      icon: PropTypes.string,
      text: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

export default SlideMenu;
