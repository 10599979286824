const defaults = require('./defaults');

const { auth, env } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  lsOnlineUrlRoot: 'https://staging.ls-www.localsearch.cloud',
  imageResizeRoot: 'https://dkw8fcvpqki80.cloudfront.net',
  auth: {
    ...auth,
    domain: 'staging.auth.localsearch.cloud',
    audience: 'https://services.lsapis.com.au',
    clientID: 'z9CZdKCEDAAKEHnz5i4AgY2w1mSuEwHp',
    redirectUri: `https://${env}.ls-business-profile.localsearch.cloud`,
  },
  deploy: {
    domain: 'ls-business-profile.localsearch.cloud',
    subDomain: `${env}.ls-business-profile.localsearch.cloud`,
    aliases: `${env}.ls-business-profile.localsearch.cloud`,
    certificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/4b32d394-d378-4ce0-a02b-38c95402e203',
  },
  api: 'https://3jbcbbsfq1.execute-api.ap-southeast-2.amazonaws.com/uat',
};
